import React, { useCallback, useState, Fragment } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { LinearProgress, Link, Pagination } from "@mui/material";
import CloseRounded from "@mui/icons-material/CloseRounded";
import ManageSearch from "@mui/icons-material/ManageSearch";
import "react-slideshow-image/dist/styles.css";
import Moment from "react-moment";
import "moment/locale/ka";
import "./orders.css";
import RowLevel2 from "../../../components/dashboard/orders/row-level2";
import RenderDelivery, {
  useRenderDeliveryMethod,
} from "./helpers/use-render-delivery";
import OrderStatus from "../../../components/dashboard/orders/order-status";
import { orderFlters, statuses, statusesForTakeByMyself } from "./helpers";
import Buyerdetails from "../../../components/dashboard/orders/buyer-details";
import PartdetailsModl from "../../../components/dashboard/orders/part-details-modal";
import SellerDetails from "../../../components/dashboard/orders/seller-details";
import CourierSearch from "../../../components/dashboard/orders/courier-search";
import PartImageLisder from "../../../components/dashboard/orders/part-image-slider";
import useGetCouriers from "../couriers/hooks/useGetCouriers";
import useGetOrderData from "./hooks/useGetOrderInfo";
import { orderType } from "./interfaces";
import useEditUserInfo from "./hooks/useEditUserInfo";
import DynamicFilter from "../../../components/common/dynamic-filter/dynamic-filter";
import ActionButton from "../../../components/common/buttons/action-button";

function Row(props) {
  const {
    row,
    setDetailsModal,
    setModal,
    setSellerModal,
    setImageModal,
    data,
    refetch,
    setPage,
    loading,
    setBuyerInfo,
    setSellerInfo,
    couriers,
  } = props;
  const rowWidth = 150;
  const [open, setOpen] = useState(false);
  const openBuyerModal = () => {
    setModal({
      status: true,
      currentUserId: row.buyerDetails.user_id,
      offerId: row.order_id,
    });
    setBuyerInfo({
      name: row.buyerDetails.first_name,
      surname: row.buyerDetails.last_name,
      mobile: row.buyerDetails.mobile,
      address: row.buyerDetails?.formatted_address,
    });
  };
  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" }, backgroundColor: "#F8F8F8" }}
      >
        <TableCell component="th" align="center" scope="row">
          #{row.id}
        </TableCell>
        <TableCell align="center" style={{ minWidth: rowWidth }}>
          <Moment locale="ka" format="LLL">
            {row.created_at}
          </Moment>
        </TableCell>
        <TableCell align="center" style={{ minWidth: 180 }}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box component="p">
              {row.first_name} {row.last_name}
            </Box>
            <IconButton onClick={openBuyerModal}>
              <ErrorOutlineIcon sx={{ fontSize: "16px" }} />
            </IconButton>
          </Box>
        </TableCell>
        <TableCell align="center">{row.mobile}</TableCell>
        <TableCell align="center">
          {RenderDelivery(
            row?.transactionSellers[0]?.delivery_method
              ? "Courier"
              : "TakeByMyself",
            row.city
          )}
        </TableCell>
        <TableCell align="center">
          <Link
            href={`https://maps.google.com/?q=${row?.address?.latitude},${row?.address?.longitude}`}
            target="_blank"
            sx={{ color: "viaro.green" }}
          >
            {row?.address?.formatted_address}
          </Link>
        </TableCell>
        {/* <TableCell  align="center">
          {row.first_level_delivery == "Courier"  ?  <CourierSearch couriers={couriers} currentCourier={row.courier} trxId={row.order_id} /> : "---"}
        </TableCell> */}
        <TableCell align="center">
          {row.status ? (
            <OrderStatus
              id={row.id}
              level={orderType.transaction}
              currentStatus={row.status}
              statusUpdatedAt={row.status_updated_at}
              statuses={
                row?.transactionSellers[0]?.delivery_method
                  ? statuses
                  : statusesForTakeByMyself
              }
              loading={loading}
              refetch={refetch}
            />
          ) : (
            <Box component={"p"} sx={{ fontSize: "11px" }}>
              განსხვავებული სტატუსი
            </Box>
          )}
        </TableCell>
        <TableCell style={{ minWidth: 180 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "viaro.green_bg",
              borderRadius: 2,
              padding: 0.2,
            }}
          >
            <Box component={"p"} sx={{ color: "viaro.green" }}>
              {row.seller_count} შეთავაზება
            </Box>
            <IconButton
              aria-label="expand row"
              size="small"
              sx={{ color: "viaro.green" }}
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={13}>
          <Collapse in={open} timeout="auto" component="tr" unmountOnExit>
            <Table aria-label="purchases" sx={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: 140 }}>გამყიდველი</TableCell>
                  <TableCell>საკონტაქტო</TableCell>
                  <TableCell align="center" style={{ maxWidth: 130 }}>
                    საწყობის მისამართი
                  </TableCell>
                  {/* <TableCell  align="center" style={{maxWidth:70}}>დაშორება</TableCell> */}
                  <TableCell align="center" style={{ maxWidth: 130 }}>
                    მიტანის ტიპი
                  </TableCell>
                  <TableCell align="center" style={{ maxWidth: 130 }}>
                    მიწოდების დრო
                  </TableCell>
                  <TableCell align="center">რეიტინგი</TableCell>
                  <TableCell align="center">
                    კომენტარი კორექტირების მოთხოვნისას
                  </TableCell>
                  <TableCell align="center">მომზადების დრო</TableCell>
                  <TableCell align="center" style={{ maxWidth: 100 }}>
                    ნაწილების რაოდენობა
                  </TableCell>
                  <TableCell align="center">ჯამური საკომისიო</TableCell>
                  <TableCell align="center">შეკვეთის ღირებულება</TableCell>
                  <TableCell align="center">კურიერი</TableCell>
                  <TableCell align="center">შეცვლა</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {row.transactionSellers?.map((rowLevel2) => (
                  <RowLevel2
                    data={data}
                    setDetailsModal={setDetailsModal}
                    setSellerModal={setSellerModal}
                    setSellerInfo={setSellerInfo}
                    buyerAddress={row.buyerDetails}
                    row={rowLevel2}
                    setImageModal={setImageModal}
                    refetch={refetch}
                    setPage={setPage}
                    city={row.city}
                    isCourier={
                      rowLevel2.delivery_method ? "Courier" : "TakeByMyself"
                    }
                    couriers={couriers}
                  />
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function Orders() {
  const [detailsModal, setDetailsModal] = useState({ status: false, data: {} });
  const [selerInfo, setSellerInfo] = useState({
    name: "",
    surname: "",
    phone: "",
    address: "",
    juridical_name: "",
    identify_code: "",
    iban: "",
  });
  const [buyerInfo, setBuyerInfo] = useState({
    name: "",
    surname: "",
    mobile: "",
    address: "",
  });
  const [buyerInfoModal, setBuyerInfoModal] = useState({
    status: false,
    currentUserId: "",
    offerId: "",
  });
  const [sellerInfoModal, setSellerInfoModal] = useState({
    status: false,
    currentUserId: "",
    offerId: "",
  });
  const [imageModal, setImageModal] = useState({
    status: false,
    uploadedImages: [],
    dbImages: [],
    partName: "",
    coordinates: { number: "", coordinates: [], groupId: "" },
  });
  const [search, setSearch] = useState({});
  const [page, setPage] = useState(1);
  const { data, isFetching, refetch } = useGetOrderData(page, search);
  const { EditUserData } = useEditUserInfo();
  const [filter, setFilter] = useState(false);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const editSellerInfo = () => {
    EditUserData(
      "seller",
      sellerInfoModal.currentUserId,
      sellerInfoModal.offerId,
      selerInfo
    ).then(() => {
      setSellerInfoModal({ status: false, currentUserId: "", offerId: "" });
      refetch();
    });
  };

  const editUserInfo = () => {
    EditUserData(
      "buyer",
      buyerInfoModal.currentUserId,
      buyerInfoModal.offerId,
      buyerInfo
    ).then(() => {
      setBuyerInfoModal({ status: false, currentUserId: "", offerId: "" });
      refetch();
    });
  };

  const callFilterApi = (data) => {
    setSearch(data);
    setTimeout(() => {
      refetch();
    }, 500);
  };

  return (
    <Box sx={{ px: 1, pt: 3 }}>
      {isFetching ? (
        <Box
          sx={{ width: "100%", position: "fixed", top: 0, left: 0, zIndex: 11 }}
        >
          <LinearProgress sx={{ backgroundColor: "viaro.green", height: 8 }} />
        </Box>
      ) : null}

      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems="center"
        pb={2}
      >
        <Box>
          <ActionButton
            title="ფილტრები"
            icon={filter ? <CloseRounded /> : <ManageSearch />}
            action={() => setFilter((prev) => !prev)}
          />
        </Box>
      </Box>
      <Box>
        <DynamicFilter
          display={filter ? "block" : "none"}
          setSearch={setSearch}
          fields={orderFlters}
          saveData={callFilterApi}
          buttonTitle={"ძებნა"}
          refetch={refetch}
          loading={isFetching}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 115 }}>შეკვეთის ID</TableCell>
              <TableCell>მოთხოვნის დრო</TableCell>
              <TableCell align="center">მყიდველი</TableCell>
              <TableCell align="center">ტელეფონი</TableCell>
              <TableCell style={{ minWidth: 130 }} align="center">
                მიტანის ტიპი
              </TableCell>
              <TableCell align="center">მისამართი</TableCell>
              {/* <TableCell  align="center">კურიერი</TableCell> */}
              <TableCell align="center">სტატუსი</TableCell>
              <TableCell align="center">შეთავაზებების რაოდენობა</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.orderData?.map((row) => (
              <Row
                data={data}
                key={row.order_id}
                row={row}
                couriers={data.couriers}
                setBuyerInfo={setBuyerInfo}
                setModal={setBuyerInfoModal}
                setSellerModal={setSellerInfoModal}
                setSellerInfo={setSellerInfo}
                setDetailsModal={setDetailsModal}
                setImageModal={setImageModal}
                refetch={refetch}
                setPage={setPage}
                loading={isFetching}
              />
            ))}
          </TableBody>
        </Table>
        {detailsModal.status ? (
          <PartdetailsModl
            detailsModal={detailsModal.status}
            setDetailsModal={setDetailsModal}
            data={detailsModal?.data}
          />
        ) : null}
        {buyerInfoModal.status ? (
          <Buyerdetails
            editUserInfo={editUserInfo}
            modal={buyerInfoModal.status}
            setModal={setBuyerInfoModal}
            userdata={buyerInfo}
            setUserData={setBuyerInfo}
          />
        ) : null}
        {sellerInfoModal.status ? (
          <SellerDetails
            editUserInfo={editSellerInfo}
            modal={sellerInfoModal.status}
            setModal={setSellerInfoModal}
            userdata={selerInfo}
            setUserData={setSellerInfo}
          />
        ) : null}

        {imageModal.status ? (
          <PartImageLisder
            partName={imageModal.partName}
            imageModal={imageModal.status}
            setImageModal={setImageModal}
            dbImages={imageModal.dbImages}
            uploadedImages={imageModal.uploadedImages}
            coordinates={imageModal.coordinates}
          />
        ) : null}
      </TableContainer>
      <Box display={"flex"} justifyContent="center" p={2}>
        <Pagination
          count={data?.last_page}
          page={page}
          onChange={handleChange}
        />
      </Box>
    </Box>
  );
}
